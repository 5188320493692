<template>
    <!-- mobile view-->
    <ul role="list" class="divide-y mobile-basket-ul lg:hidden">
      <li class="flex items-start py-4"
        v-for="(item, index) of order.items" :key="index">
        <div class="grow">
          <template v-if="item.type === 'user'">
            <p class="grid grid-cols-2">Program tarihi: <b>{{ item.date }}</b></p>
            <p class="grid grid-cols-2">Program: <b>{{ item.program }}</b></p>
          </template>
          <p v-else><i>Programı Açık Radyo seçecek</i></p>
          <p class="grid grid-cols-2">Toplam destek:
            <b>{{ item.desiredHours === 0.5 ? 'Yarım' : item.desiredHours }} saat</b>
          </p>
          <p class="grid grid-cols-2">Anons: <b>{{ item.announceNames }}</b></p>
          <p class="grid grid-cols-2" v-if="item.programCriteria.trim() !== ''">
            Program kriterleri: <b>{{ item.programCriteria }}</b>
          </p>
        </div>
        <div v-if="editable">
          <Button severity="danger" icon="pi pi-trash" outlined raised
            @click="$emit('delete-item', index)" size="small" />
        </div>
      </li>
      <li class="py-4">
        <p class="flex justify-between">Toplam destek:
          <span class="rounded-sm bg-primary text-white p-1 px-1.5">
            {{ order.credits === 1 ? 'Yarım' :  order.credits / 2 }} saat
            / ₺{{ order.total }}
          </span>
        </p>
      </li>
    </ul>

    <!-- large screen view -->
    <table class="w-full basket-table hidden lg:table">
      <thead>
        <tr>
          <th>Tarih</th>
          <th>Program</th>
          <th>Süre</th>
          <th>Anons</th>
          <th>Program kriterleri</th>
          <th v-if="editable">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) of order.items" :key="index">
          <td v-if="item.type === 'user'">{{ item.date }}</td>
          <td :colspan="item.type === 'ar' ? 2 : 1">
            <span v-if="item.type === 'user'">{{ item.program }}</span>
            <i v-else>Programı Açık Radyo seçecek</i>
          </td>
          <td>{{ item.desiredHours === 0.5 ? 'Yarım' : item.desiredHours }} saat</td>
          <td>{{ item.announceNames }}</td>
          <td>{{ item.programCriteria }}</td>
          <td v-if="editable">
            <Button severity="danger" icon="pi pi-trash" outlined raised
              @click="$emit('delete-item', index)" size="small" />
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="2" class="text-left">Toplam destek:</th>
          <th class="text-left" colspan="4">
            <span class="rounded-sm bg-primary text-white p-1 px-1.5">
              {{ order.credits === 1 ? 'Yarım' :  order.credits / 2 }} saat
              / ₺{{ order.total }}
            </span>
          </th>
        </tr>
      </tfoot>
    </table>
</template>

<script>
export default {
  emits: ['delete-item'],

  props: ["order", "editable"],
}
</script>

<style scoped>
.basket-table thead th {
  @apply text-left;
}
.basket-table th, .basket-table td {
  @apply py-1 border-b;
}
.mobile-basket-ul p {
  @apply mb-1
}
</style>
<template>
  <form @submit.prevent="submit">
    <Card class="max-w-screen-sm p-4 mt-16 mx-auto">
      <template #title>
        <h1>Açık Radyo Program Destekleme</h1>
      </template>
      <template #subtitle>
        <h2>Üye Kaydı</h2>
      </template>

      <template #content>
        <form-row>
          <p class="mb-4">Lüften Açık Radyo program destekçisi olarak kayıt olunuz.</p>
          <p>Daha önce kayıt olduysanız
            <router-link :to="{name: 'home'}"><b>başlangıç sayfasına</b></router-link>
            giderek üye girişi yapabilirsiniz.
          </p>
        </form-row>
        <form-row><hr /></form-row>
        <form-row>
          <form-item label="Adınız ve soyadınız:">
            <InputText type="text" class="w-full" v-model="register.ad_soyad"
              required maxlength="60" />
          </form-item>
        </form-row>
        <form-row>
          <form-item label="E-posta adresiniz">
            <InputText type="email" class="w-full" v-model="register.email"
              required minlength="6" maxlength="60" />
          </form-item>
        </form-row>
        <form-row>
          <form-item label="Şifreniz">
            <InputText type="password" class="w-full" v-model="register.password"
              minlength="6" required />
          </form-item>
        </form-row>
        <Message class="mb-0" v-if="error" severity="warn" :closable="false">{{error}}</Message>
        <form-row>
          <Button type="submit" label="KAYDET" class="w-full" raised :loading="ui.loading" />
        </form-row>
      </template>
    </Card>
  </form>
</template>

<script>
export default {
  components: {},

  props: [],

  data () {
    return {
      register: {
        ad_soyad: null,
        email: null,
        password: null,
      },
      ui: {
        loading: false,
      },
      error: null,
    }
  },

  methods: {
    async submit () {
      this.ui.loading = true;
      this.error = null;
      const ret = await this.$post("/register", this.register);
      this.ui.loading = false;
      if (ret.err) return this.error = ret.err;
      this.$store.member = ret.member;
    }
  },

  watch: {
    "$store.member": {
      handler () {
        if (this.$store.member) this.$router.replace({name: 'member-info'});
      }, immediate: true
    }
  },
}
</script>
<template>

  <li class="mb-4">
    <form-item>

        <Fieldset :legend="programSlot.date">

          <Dropdown :options="programSlot.options" optionLabel="text"
            v-model="selectedCredits" :showClear="!! selectedCredits"
            @change="$emit('update:value', selectedCredits)"
            optionValue="credits" dataKey="text" class="w-full md:w-48 mr-4 mb-4 md:mb-0" />

        </Fieldset>

    </form-item>
  </li>

  </template>

  <script>
  import Fieldset from 'primevue/fieldset';

  export default {
    components: { Fieldset },
    props: ["programSlot", "creditsToHoursStr"],

    emits: ["update:value"],

    data () {
      return {
        selectedCredits: this.programSlot ? this.programSlot.desiredCredits : 0,
      }
    },
  }
  </script>

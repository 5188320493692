import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Register from '../views/Register.vue';
import MemberInfo from '../views/MemberInfo.vue';
import Support from '../views/support/Support.vue';
import Basket from '../views/basket/Basket-Pay.vue';
import ThankYou from '../views/ThankYou.vue';

const routes = [
  //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  { path: '/', name: 'home', component: Home },
  { path: '/kayit', name: 'register', component: Register },
  { path: '/bilgilerim', name: 'member-info', component: MemberInfo },
  { path: '/destek/:step', name: 'support', component: Support },
  { path: '/odeme', name: 'basket', component: Basket },
  { path: '/tesekkur', name: 'thank-you', component: ThankYou },
  { path: '/sifremi-unuttum', name: 'pass-forgot',
    component: () => import("../views/Pass-Forgot.vue") },
  { path: '/sifremi-yenile/:userId/:key', name: 'pass-reset',
    component: () => import("../views/Pass-Reset.vue") },
  { path: '/fatura-iste/:memberId/:token', name: 'request-receipt',
    component: () => import("../views/RequestReceipt.vue") },

  //static content:
  { path: '/kvkk', name: 'content-kvkk', component: () => import('../views/content/kvkk.vue') },
  { path: '/iptal-iade', name: 'content-iptal-iade', component: () => import('../views/content/iptal-iade.vue') },
  { path: '/sozlesme', name: 'content-sozlesme', component: () => import('../views/content/sozlesme.vue') },
  { path: '/kullanim-kosullari', name: 'content-kullanim-kosullari',
    component: () => import('../views/content/2021-kullanim-kosullari.vue') },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router;

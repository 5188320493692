<template>
  <Message severity="warn" :closable="false">
    <div class="p-4">
      <p>{{ violation["violation-error"] }}</p>
      <div class="border-b my-4 border-black"></div>
      <ul>
        <li v-for="(v, index) of violation.violators" :key="index">
          {{ v.program }} {{ v.userItem.date }}
        </li>
      </ul>
    </div>
  </Message>
</template>

<script>
export default {
  props: ["violation"],
}
</script>
<template>
  <div v-if="currentItem">

    <Card class="max-w-screen-md lg:p-4 py-0 mx-auto mb-4 text-sm lg:text-base">
      <template #title>
        <h1>Destek</h1>
      </template>
      <template #content>
        <Steps :model="steps" :readonly="false" aria-label="Destek Adımları" />
      </template>
    </Card>

    <SupportType :current-item="currentItem"
      v-if="$route.params.step === 'destek-tipi'"
      class="max-w-screen-md lg:p-4 mx-auto" />

    <SupportDetail :current-item="currentItem"
      v-if="$route.params.step === 'destek-detayı'"
      class="max-w-screen-md lg:p-4 mx-auto"
      :programs="programs" :pricing="pricing"
      />

    <SupportAnnounce :current-item="currentItem"
      v-if="$route.params.step === 'anons'"
      class="max-w-screen-md lg:p-4 mx-auto"
      @add="$event => add($event)"
      />

    <Card class="max-w-screen-md lg:p-4 mx-auto mt-4">
      <template #content>
        <p>Şu anda yaptığınız seçime ait destek bedeli:
          <b>₺{{ currentTotal }} </b>.</p>
        <p>Bu oturumdaki toplam destek bedeli:
          <b>₺{{ $store.order.total }} </b>.</p>
      </template>
    </Card>
  </div>

</template>

<script>
import Steps from 'primevue/steps';
import SupportType from './1-Type.vue';
import SupportDetail from './2-Detail.vue';
import SupportAnnounce from './3-Announce.vue';

export default {
  components: { Steps,
    SupportType, SupportDetail, SupportAnnounce
  },

  props: [],

  data () {
    return {
      currentItem: null,
      programs: null,
      pricing: null,
    }
  },

  computed: {
    steps () {
      return [
        { label: "Destek tipi", to: {name: 'support', params: {step: 'destek-tipi'}} },
        { label: "Destek detayı", to: {name: 'support', params: {step: 'destek-detayı'}} },
        { label: "Anons", to: {name: 'support', params: {step: 'anons'}},
          disabled: this.currentItem.type === 'user' && ! this.currentItem.slots.length},
      ];
    },

    currentTotal () {
      if (! this.currentItem) return 0;
      const credits = this.currentItem.type === 'user'
        ? this.currentItem.slots.reduce((acc, slot) => acc + slot.desiredCredits, 0)
        : this.currentItem.desiredCredits;
      if (! credits) return 0;
      let unitPrice = this.$store.pricing[credits === 1 ? 'expensive' : 'normal'];
      //Following line is needed because if the user has items in the basket, all prices start normal
      if (this.$store.order.total) unitPrice = this.$store.pricing.normal;
      return credits * unitPrice;
    },
  },

  methods: {
    createItem () {
      this.currentItem = {
        type: "user",
        desiredCredits: 2,
        desiredHours: 1, //ar selector uses hours
        programCriteria: "",
        slots: [],
        announceNames: this.$store.member.ad_soyad,
        program: null,
      }
    },

    add (thenWhat) {
      if (this.currentItem.type === 'user') {
        //generate items from slots:
        const newItems = this.currentItem.slots.map(slot => {
          const item = Object.assign({}, this.currentItem, slot);
          item.desiredHours = item.desiredCredits / 2;
          delete item.slots;
          return item;
        });

        this.$store.order.items = this.$store.order.items.concat(newItems);
      } else {
        this.$store.order.items.push(this.currentItem)
      }

      thenWhat === 'reset'
        ? this.$router.replace({name: "support", params: {step: 'destek-tipi'}})
        : this.$router.replace({name: "basket"}); //thenWhat === 'basket'
    },
  },

  watch: {
    "$route.params.step": {
      handler () {
        if (this.$route.name !== 'support') return; //otherwise changing the route in "add" affects us.
        if (this.$route.params.step === 'destek-tipi') {
          this.createItem();
        } else if (! this.currentItem) {
          this.$router.replace({name: "support", params: {step: 'destek-tipi'}});
        }
      }, immediate: true,
    },
  },

  async created () {
    const ret = await this.$get("/reservation");
    if (ret.err) return alert(ret.err);
    ["programs", "pricing"].forEach(f => this[f] = ret[f]);
    this.$store.pricing = this.pricing;
  }
}
</script>

<style scoped>
:deep(.p-steps) {
  @apply p-0;
}
</style>
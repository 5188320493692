<template>
  <div>
    <Card class="p-4 py-0 mx-auto max-w-screen-lg">
      <template #title>
        <h1>Desteğiniz</h1>
      </template>
      <template #subtitle>
        <p v-if="$store.order.items.length">
          Destek listeniz aşağıdadır. <br class="md:hidden">
          Daha çok destek olmak için
          <router-link :to="{name: 'support', params: {step: 'destek-tipi'}}">tıklayınız</router-link>.
        </p>
        <p v-else>
          Desteğiniz bulunmuyor. Destek olmak için
          <router-link :to="{name: 'support', params: {step: 'destek-tipi'}}">tıklayınız</router-link>.
        </p>
      </template>

      <template #content v-if="$store.order.items.length">
        <Basket :order="$store.order" :editable="true" @delete-item="deleteItem" />
      </template>
    </Card>

    <!-- gib -->
    <Card v-if="! serverHasTc && gibMinimum && $store.order.total >= gibMinimum"
        class="p-4 py-0 mx-auto max-w-screen-lg mt-4">
      <template #title>
        <h2>TC Kimlik No</h2>
      </template>
      <template #subtitle>
        <p><strong>{{ gibMinimum }} TL</strong> üzerindeki harcamalara ait
          faturaların e-Arşiv fatura olarak
          Gelir İdaresi Başkanlığı üzerinden düzenlenmesi zorunluluğu bulunmaktadır.
          Bu nedenle TC Kimlik numaranıza ihtiyaç duyuyoruz. TC kimlik numaranız yoksa
          veya yabancı uyrukluysanız lüften ilgili kutucuğu işaretleyin.
        </p>
        <hr class="border-b my-4" />
      </template>

      <template #content>
        <form @submit.prevent="tcSubmit">
          <div class="text-center">
            <form-row-item label="11 haneli TC kimlik numaranız" class="mb-8" v-if="! noTc">
              <InputText v-model="tc" pattern="[0-9]{11}" required />
            </form-row-item>
            <form-row>
              <Checkbox :binary="true" v-model="noTc" inputId="no-tc" />
              <label class="cursor-pointer text-sm" for="no-tc">
                TC kimlik numaram yok
              </label>
            </form-row>
            <Message severity="warning" v-if="ui.tcSubmitError">
              {{ ui.tcSubmitError }}
            </Message>
            <form-row>
              <Button type="submit" label="Kaydet" :loading="ui.tcSubmitLoading" />
            </form-row>
          </div>
        </form>
      </template>
    </Card>

    <!-- payment -->
    <Card v-if="$store.order.items.length && ! tcRequired"
      class="p-4 py-0 mx-auto max-w-screen-lg mt-4">
      <template #title>
        <h2>Ödeme</h2>
      </template>
      <template #subtitle>
        <p>Lütfen ödeme metodunu seçiniz.</p>
        <hr class="border-b my-4" />
      </template>

      <template #content>
        <TabView class="w-full lg:w-2/3 mx-auto">
          <TabPanel header="KREDİ KARTI">

            <Panel header="Ödeme Seçenekleri" class="mt-4">
              <ul class="flex flex-col gap-6 mt-4">
                <li class="payment-option-li">
                  <label class="cursor-pointer flex items-center gap-2">
                    <RadioButton v-model="repeated" name="repeat-payment" :value="false" />
                    <strong>Peşin veya Banka Taksitli Ödeme:</strong>
                  </label>
                  <p class="mt-4">
                    Tüm banka veya kredi kartları ile peşin ödeme yapabilirsiniz.
                  </p>
                  <p class="mt-4">
                    Akbank, Garanti Bankası, Türkiye İş Bankası veya Yapı Kredi Bankası kredi
                    kartları ile taksitli ödeme yapabilirsiniz.
                  </p>
                </li>
                <li class="payment-option-li">
                  <label class="cursor-pointer flex items-center gap-2">
                    <RadioButton v-model="repeated" name="repeat-payment" :value="true" />
                    <strong>Aylık Otomatik Ödeme:</strong>
                  </label>
                  <p class="mt-4">
                    Bu ödeme yönteminde desteğiniz 12 aya kadar istediğiniz sayıda taksite bölünerek
                    kredi kartınızdan tahsil edilir ve toplam tutar kart limitinizden düşülmez.
                  </p>
                  <div v-show="repeated">
                    <form-row-item label="Taksit sayısı" class="font-bold mt-4">
                      <Dropdown :options="[2,3,4,5,6,7,8,9,10,11,12]"
                        v-model.number="repeatMonths" class="block w-full"
                      />
                    </form-row-item>
                    <p class="mt-4">
                      Ödeme planı şu şekilde olacaktır:
                      <b>{{ repeatMonths }} ay ×
                        ₺{{ ($store.order.total / repeatMonths).toFixed(2) }}
                      </b>
                    </p>
                  </div>
                </li>
              </ul>
            </Panel>

            <form @submit.prevent="payCC" class="py-4 mt-4" id="cc-form">
              <form-row-item label="Kartın üzerindeki isim">
                <InputText v-model="cc.name" required class="w-full" autocomplete="cc-name" />
              </form-row-item>
              <form-row-item label="Kart no">
                <InputText type="text" v-model="cc.no" required class="w-full" inputmode="numeric"
                  autocomplete="cc-number" pattern="[0-9]{4} ?[0-9]{4} ?[0-9]{4} ?[0-9]{3,4}"
                />
              </form-row-item>
              <div class="grid grid-cols-2 gap-2">
                <form-row-item label="Ay">
                  <!-- <Dropdown v-model="cc.month" required class="w-full" :options="months"
                    option-value="value" option-label="label" /> -->
                  <select v-model="cc.month" autocomplete="cc-exp-monnth" required>
                    <option value="">Ay</option>
                    <option v-for="m of months" :key="m.label">{{ m.value }}</option>
                  </select>
                </form-row-item>
                <form-row-item label="Yıl">
                  <!-- <Dropdown v-model="cc.year" required class="w-full" :options="years"
                    option-value="value" option-label="label" /> -->
                  <select v-model="cc.year" autocomplete="cc-exp-year" required>
                    <option value="">Yıl</option>
                    <option v-for="y of years" :key="y.label">{{ y.label }}</option>
                  </select>
                </form-row-item>
              </div>
              <form-row-item label="CCV">
                <InputText type="text" v-model="cc.ccv" required class="w-24" inputmode="numeric"
                  pattern="[0-9]{3,4}" autocomplete="cc-csc" />
              </form-row-item>

              <!-- installment -->
              <form-row>
                <p v-if="! bin && ! repeated">
                  <span v-if="ccNoFirst6">
                    Bu karta taksit yapılamamaktadır.
                  </span>
                  <span v-else>
                    Kart numaranızı girdiğinizde taksitle ilgili bilgiler
                    burada görüntülenecektir.
                  </span>
                </p>
                <table class="w-full installment-table" v-if="bin && ccNoFirst6 && ! repeated">
                  <thead>
                    <tr>
                      <th class="text-left">Taksit</th>
                      <th class="text-right">Aylık</th>
                      <th class="text-right">Toplam</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="i of maxInstallment" :key="i">
                      <td class="flex items-center gap-2">
                        <RadioButton binary name="installment" v-model="installment" :value="i" />
                        {{ i }}
                      </td>
                      <td class="text-right">₺{{ ($store.order.total / i).toFixed(2) }}</td>
                      <td class="text-right">₺{{ $store.order.total.toFixed(2) }}</td>
                    </tr>
                  </tbody>
                </table>
              </form-row>

              <form-row>
                <Message severity="error" v-if="ccError">{{ ccError }}</Message>
                <Violation :violation="violation" v-if="violation" />
              </form-row>
              <form-row class="text-right">
                <Button type="submit" :loading="ui.ccPaymentLoading"
                  raised label="GÖNDER" class="w-full" />
              </form-row>
            </form>
          </TabPanel>

          <TabPanel header="HAVALE">
            <form @submit.prevent="payWire()" id="wire-form">
              <p class="my-4">
                Havale işleminiz için gereken bilgiler rezervasyonunuzu kaydettikten sonra,
                bir sonraki sayfada görüntülenecektir.
              </p>
              <form-row>
                <Message severity="error" v-if="wireError">{{ wireError }}</Message>
                <Violation :violation="violation" v-if="violation" />
              </form-row>
              <footer class="text-center">
                <Button type="submit" :loading="ui.wirePaymentLoading"
                  raised label="REZERVASYONUMU KAYDET" />
              </footer>
            </form>
          </TabPanel>
        </TabView>
      </template>
    </Card>

  </div>
</template>

<!--
  select {
    @apply block p-2.5 pr-10 bg-white border-psn-input-focus border rounded-sm
      text-psn-light-text font-bold appearance-none
      focus:outline-none focus:ring focus:border-blue-300
      bg-no-repeat
  }

  select {
    background-image: url("./images/caret-down.png");
    background-repeat: no-repeat;
    background-position: right 0.8rem center;
    background-size: 12px auto;
  }

  /* p-input text because of PrimeVue InputNumber component */
  input[type=text], input[type=email], input[type=number], .p-inputtext {
    @apply p-2.5 bg-white border-psn-input-focus border rounded-sm
      text-psn-light-text font-bold
      focus:outline-none focus:ring focus:border-blue-300
  }

  /* input:invalid, select:invalid {
    @apply border-red-800 focus:ring focus:border-red-800 !important;
  } */
-->

<script>
import Basket from "./basket.vue";
import Violation from "./Violation.vue";
import Panel from 'primevue/panel';

export default {
  components: { Basket, Violation, Panel },

  data () {
    return {
      cc: {
        name: this.$store.member.ad_soyad,
        no: "", month: "", year: "", ccv: "",
      },

      ccError: "",
      wireError: "",
      violation: null,
      binList: {},
      gibMinimum: 0,
      installment: 1,
      tc: null,
      noTc: false,
      serverHasTc: false,

      repeated: false,
      repeatMonths: 2,

      ui: {
        ccPaymentLoading: false,
        wirePaymentLoading: false,
        tcSubmitLoading: false,
        tcSubmitError: null,
      }
    }
  },

  computed: {
    tcRequired () {
      return ! this.serverHasTc && this.gibMinimum
        && this.$store.order.total >= this.gibMinimum;
    },

    months () {
      return Array(12).fill(1).map((m, i) => m + i)
        .map(i => i < 10 ? ("0" + i) : i)
        .map(i => ({label: i, value: i}));
    },

    years () {
      const y = this.$dayjs().year();
      return Array(10).fill(y).map((year, i) => year + i)
        .map(i => ({label: "" + i, value: "" + i}));
    },

    maxInstallment () {
      if (! this.bin) return 1;
      if (this.bin.cardGroup === 'WORLD') return 6;
      return 9;
    },

    ccNoFirst6 () {
      const no = this.cc.no.replaceAll(" ", "");
      const bin = no.substring(0, 6);
      if (bin.length < 6) return null;
      return bin;
    },

    bin () {
      return this.binList[this.ccNoFirst6];
    },
  },

  watch: {
    bin: {
      handler () {
        if (! this.bin) return this.installment = 1;
        if (this.installment > this.maxInstallment) this.installment = this.maxInstallment;
      },
    },

    repeated: {
      handler () {
        if (this.repeated) this.installment = 0;
      },
    },
  },

  methods: {
    async tcSubmit () {
      this.ui.tcSubmitError = null;
      this.ui.tcSubmitLoading = true;
      const ret = await this.$post("/tc-submit", {tc: this.noTc ? '-' : this.tc});
      this.ui.tcSubmitLoading = false;
      if (ret.err) return this.ui.tcSubmitError = ret.err;
      this.serverHasTc = true;
    },

    async payCC () {
      this.ui.ccPaymentLoading = true;
      this.violation = null;
      this.ccError = null;
      const post = {
        order: this.$store.order,
        method: "cc",
        cc: this.cc,
        installment: ! this.repeated ? this.installment : 1,
        repeated: this.repeated,
        repeatMonths: this.repeatMonths ? this.repeatMonths : null,
      };
      const ret = await this.$post("/process-order", post);
      this.ui.ccPaymentLoading = false;
      if (ret.err) return this.ccError = ret.err;
      if (ret["violation-error"]) return this.violation = ret;
      Object.keys(ret.order).forEach(key => this.$store.order[key] = ret.order[key]);
      this.$router.replace({name: "thank-you"});
    },

    async payWire () {
      this.ui.wirePaymentLoading = true;
      this.violation = null;
      this.wireError = null;
      const post = { order: this.$store.order, method: "havale" };
      const ret = await this.$post("/process-order", post);
      this.ui.wirePaymentLoading = false;
      if (ret.err) return this.wireError = ret.err;
      if (ret["violation-error"]) return this.violation = ret;
      //add new fields. Note that Object.assign didn't work.
      Object.keys(ret.order).forEach(key => this.$store.order[key] = ret.order[key]);
      this.$router.replace({name: "thank-you"});
    },

    deleteItem (index) {
      this.$store.order.items.splice(index, 1);
    },
  },

  async created () {
    if (! this.$store.order.items.length)
      return this.$router.replace({name: "support", params: {step: 'destek-tipi'}});

    const ret = await this.$get("/checkout");
    this.binList = ret.binList;
    this.gibMinimum = ret.gibMinimum;
    this.serverHasTc = ret.hasTc;
  }
}
</script>

<style scoped>
.installment-table td, .installment-table th {
  @apply py-2 border-b;
}

select {
  @apply appearance-none;
  @apply w-full border border-black h-11 rounded border-opacity-40 px-1.5;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAACwAAAAD3WUemAAABX0lEQVQoFZWSTUvDQBCGZyZ+IOpP8Ni7d1EUPewmFbH1Aw9Jq+DfstR4EbUo2k0uXgT/gJce+jPUg9XsOtMPTLVQOxCy8+Z9n50Ni0EQbWWZOwOg4zStP8IEpVRlE8DWPA9PyFpbA3BLiNk9Qzf+yxGvZCQrDEKEU0T8cA7mrHUPxWJ1fRxMPOKVjGS7DAkpFfrcNJxzswD4TkS+MfWnUUDfr6zxBIYnme8PUErT2JCYuwuiMsM6YhBjEFRXf4NE+4FAhzcsS1Z8mDdrHW4z6JpHnmH9DdFTSVJ/Fo/WlRXnspSXC70NcS9JYv5HvRoCieT70Q6f/4qB0zz6K5FToluLKR99kff+JMJ9Y87vRB/UH5B8UCraZdDlACbaAILoDpLk4la0fI0EiSEIwhLfL4bBVD/wxfflsNmMG/1+6OUNdbmm3X5pFQrLLZ6lxLJFpCNj4pucZbKl1pGWZ1zqGz8emsI5XdyvAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 12px auto;
}

select:invalid {
  @apply border-red-600;
}

.payment-option-li {
  @apply border p-4 border-slate-500 rounded-sm;
}
</style>
<template>
  <label class="block">
    <span class="block mb-2 text-base">{{label}}</span>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: ["label"]
}
</script>
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <Card>
    <template #title>
      <h2>Anons isimleri</h2>
    </template>

    <template #subtitle>
      <p class="mb-6">
        İsminiz ya da belirttiğiniz isim(ler), seçtiğiniz ya da sizin için seçilen
        program(lar)ın başında ve sonunda anılacak ve teşekkür listemizde yer alacaktır.
      </p>
      <p>
        <strong>
          Kurumsal isimlerin anons edilemeyeceğini, sadece kişilerin ve kâr amacı gütmeyen resmi dernek,
          vakıf gibi kuruluş adlarının anons edilebileceğini lûtfen unutmayınız.
        </strong>
      </p>
      <hr class="border-b my-4" />
    </template>

    <template #content>
      <form-row-item label="Sizin adınız dışında ve/veya adınızla birlikte adının anılmasını istediğiniz isim(ler)i belirtiniz.">
        <Textarea v-model="currentItem.announceNames" class="w-full" required />
      </form-row-item>
    </template>

    <template #footer>
      <div class="grid grid-cols-2 md:flex justify-end gap-2">
        <div>
          <Button size="small" label="EKLE VE BAŞA DÖN" severity="success"
            raised class="w-full lg:w-auto"
            @click="$emit('add', 'reset')" />
        </div>
        <div>
          <Button size="small" label="EKLE VE BİTİR" class="w-full lg:w-auto"
            raised @click="$emit('add', 'basket')"  />
        </div>


      </div>
    </template>
  </Card>
</template>

<script>
export default {
  components: {},

  emits: ["add"],

  props: ["currentItem"],
}
</script>
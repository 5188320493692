<template>
  <form-row>
    <form-item :label="label">
      <slot></slot>
    </form-item>
  </form-row>
</template>

<script>
export default {
  props: ["label"],
}
</script>
import { computed, reactive, shallowReactive, watch, watchEffect } from "vue";
import dayjs from "dayjs";

let member = null, order = {items: []}, pricing = null, settings = null;

//read from storage to initiate:
const j = localStorage.getItem("ar-destek-data");
if (j) {
  const object = JSON.parse(j);
  const latest = dayjs().subtract(1, "day");
  if (object.date && dayjs(object.date).isBefore(latest)) {
    //do nothing.
  } else { //use values in storage:
    member = object.member;
    order = object.order;
    pricing = object.pricing;
  }
}

order = reactive(order); //without this, items.length change doesn't trigger a watch

order.credits = computed(() => {
  return order.items.reduce((acc, item) => acc + item.desiredCredits, 0);
});

order.reset = () => {
  order.items = [];
}

const store = shallowReactive({member, order, pricing, settings});

watch(() => store.member, () => {
  if (! store.member)
    store.order.reset();
});

order.total = computed(() => {
  if (! store.pricing || ! order.credits) return 0;
  const price = order.credits > 1 ? store.pricing.normal : store.pricing.expensive;
  return order.credits * price;
});

function persist () {
  const write = {
    date: new Date(),
    member: store.member,
    order: {items: store.order.items},
    pricing: store.pricing
  };
  localStorage.setItem("ar-destek-data", JSON.stringify(write));
}

watch(() => {
  return [store.member, store.pricing];
}, persist );

watchEffect(() => {
  store.order.items;
  persist();
})

export default store;

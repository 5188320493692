const qs = require("qs");
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

//const base = process.env.VUE_APP_API_URL;
//const base = "http://localhost:8082";
const base = "/user";

export default {

  async post (uri, body) {

    const response = await fetch(base + uri, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    });

    return await response.json();
  },

  async get (uri, params) {
    let q = qs.stringify(params);
    if (q) q = "?" + q;
    const response = await fetch(base + uri + q , {
      method: 'GET',
      headers,
    });

    return await response.json();
  },

}
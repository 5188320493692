import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import dayjs from 'dayjs';
import trLocale from "dayjs/locale/tr"
dayjs.locale(trLocale);

import ajax from "./lib/ajax.js";
import date from "./lib/date.js";
import store from "./lib/store.js";
import * as Sentry from "@sentry/vue";

//import "@/assets/theme.css";
import "@/assets/main.css";
import "primevue/resources/themes/mdc-light-deeppurple/theme.css"
import "primevue/resources/primevue.min.css"                 //core css
import 'primeicons/primeicons.css';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

const app = createApp(App);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: "https://071802fe59cc44dba48ff6a741dbb1f2@o335470.ingest.sentry.io/4505205998682112",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router).use(PrimeVue, {inputStyle: 'outlined'});

app.config.globalProperties.$get = ajax.get;
app.config.globalProperties.$post = ajax.post;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$date = date;
app.config.globalProperties.$store = store;

//app's imports:
import formRow from "./components/form-row";
import formItem from "./components/form-item";
import formRowItem from "./components/form-row-item";

app.component("form-row", formRow);
app.component("form-item", formItem);
app.component("form-row-item", formRowItem);

app.component("Button", Button);
app.component("Dropdown", Dropdown);
app.component("Card", Card);
app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("Message", Message);
app.component("Checkbox", Checkbox);
app.component("RadioButton", RadioButton);
app.component("Dialog", Dialog);
app.component("InputNumber", InputNumber);
app.component("OverlayPanel", OverlayPanel);
app.component("Menu", Menu);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);

app.mount('#app');

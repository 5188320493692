<template>
  <form @submit.prevent="submit">
    <Card class="max-w-screen-sm p-4 mt-16 mx-auto">
      <template #title>
        <h1>Açık Radyo Program Destekleme</h1>
      </template>
      <template #subtitle>
        <h2>Uygulamaya hoşgeldiniz.</h2>
      </template>

      <template #content>
        <form-row class="pt-2">
          <p class="mb-2">Daha önce üye olduysanız aşağıdaki formu kullanarak giriş yapın.</p>
          <p>Destek programına üye değilseniz
            <b><router-link :to="{name: 'register'}">lüften tıklayın</router-link></b>.
          </p>
        </form-row>
        <form-row><hr /></form-row>
        <form-row-item label="E-posta adresiniz">
          <InputText type="email" class="w-full" v-model="login.email"
            inputmode="email" autocomplete="email" required />
        </form-row-item>
        <form-row-item label="Şifreniz">
          <InputText type="password" class="w-full" v-model="login.password"
            autocomplete="current-password" required />
        </form-row-item>
        <!-- <form-row>
          <div class="flex items-center">
            <Checkbox input-id="remember-me" v-model="login.rememberMe" :binary="true" />
            <label class="pl-1" for="remember-me">Beni hatırla</label>
          </div>
        </form-row> -->
        <Message v-if="error" severity="error" :closable="false">{{error}}</Message>
        <form-row class="mt-4">
          <Button type="submit" label="GİRİŞ" class="w-full" raised :loading="ui.loading" />
        </form-row>
        <form-row class="text-right">
          <router-link :to="{name: 'pass-forgot'}">Şifremi unuttum</router-link>
        </form-row>
      </template>
    </Card>
  </form>
</template>

<script>
export default {
  emits: ["member"],

  data() {
    return {
      error: "",
      login: {
        rememberMe: true,
        email: "",
        password: "",
      },
      ui: {
        loading: false,
      }
    };
  },

  methods: {
    async submit () {
      this.error = null;
      this.loading = true;
      const ret = await this.$post("/login", this.login);
      this.loading = false;
      if (ret.err) return this.error = ret.err;
      this.$emit('member', ret.member);
    }
  }
}
</script>

<template>
  <div></div>
</template>

<script>
export default {
  created () {
    if (this.$store.member)
      this.$router.replace({name: "member-info"});
  }
}
</script>
<template>
  <Card class="max-w-screen-md p-4 mx-auto">
    <template #title>
      <h1>Bilgilerim</h1>
    </template>
    <template #subtitle>
      <p>Bilgilerinizin doğru, güncel ve eksiksiz olması iletişimimiz açısından son derece önemlidir.</p>
    </template>
    <template #content>
      <form @submit.prevent="submit" id="member-info-form">
        <form-row-item label="Adınız ve soyadınız *" >
          <InputText v-model="member.ad_soyad" required type="text" maxlength="50"
            autocomplete="name" />
        </form-row-item>
        <form-row-item label="E-posta adresiniz *" >
          <InputText v-model="member.email" required type="email" maxlength="60" minlength="10"
            autocomplete="email" inputmode="email" />
        </form-row-item>
        <form-row-item label="Adresiniz *" >
          <Textarea v-model="member.adres" required maxlength="100"
            autocomplete="street-address" />
        </form-row-item>
        <form-row-item label="İlçe *" >
          <InputText v-model="member.ilce" required type="text" maxlength="50"
            autocomplete="address-level2" />
        </form-row-item>
        <form-row-item label="İl *" >
          <InputText v-model="member.sehir" required type="text" maxlength="50"
            autocomplete="address-level1" />
        </form-row-item>
        <form-row-item label="Posta kodu" >
          <InputText v-model="member.posta_kodu" type="text" maxlength="15"
            autocomplete="postal-code" />
        </form-row-item>
        <form-row-item label="Ülke *" >
          <InputText v-model="member.ulke" required type="text" maxlength="50"
            autocomplete="country-name" />
        </form-row-item>
        <form-row>
          <p><i>En az bir telefon alanını doldurmanız gerekmektedir.</i></p>
        </form-row>
        <form-row-item label="Ev telefonu" >
          <InputText v-model="member.tel1" type="tel" maxlength="20" autocomplete="tel" />
        </form-row-item>
        <form-row-item label="İş telefonu" >
          <InputText v-model="member.tel2" type="tel" maxlength="20" autocomplete="tel" />
        </form-row-item>
        <form-row-item label="Cep telefonu" >
          <InputText v-model="member.tel3" type="tel" maxlength="20" autocomplete="tel" />
        </form-row-item>
        <form-row-item label="T.C. kimlik numarası (e-fatura mükellefiyseniz T.C. kimlik numarası zorunludur.)" >
          <InputText v-model="member.tc" type="text" minlength="11" maxlength="11"
            pattern="[0-9]{11}" />
        </form-row-item>
        <form-row class="mt-8 flex items-center">
          <Checkbox :binary="true" class="mr-2" v-model="invoiceDifferent" id="invoice-different" />
          <label for="invoice-different" class="cursor-pointer"
            @click="invoiceDifferent = ! invoiceDifferent">Fatura bilgilerim yukarıdakinden farklı
          </label>
        </form-row>
        <div v-if="invoiceDifferent">
          <form-row-item label="Şirket adı *">
            <InputText v-model="member.fatura_ad" required type="text" maxlength="100" />
          </form-row-item>
          <form-row-item label="Fatura adresi *">
            <Textarea v-model="member.fatura_adres" required type="text" maxlength="100" />
          </form-row-item>
          <form-row-item label="Vergi dairesi *">
            <InputText v-model="member.vd" required type="text" maxlength="50" />
          </form-row-item>
          <form-row-item label="Vergi numarası *">
            <InputText v-model="member.vno" required type="text" maxlength="15" />
          </form-row-item>
        </div>
      </form>
    </template>
    <template #footer>
      <Message class="mb-0" v-if="error" severity="error" :closable="false">{{error}}</Message>
      <div class="text-right">
        <Button type="submit" form="member-info-form" icon="pi pi-save" label="KAYDET"
          raised size="small" :loading="ui.loading" />
      </div>
    </template>
  </Card>
</template>

<script>
export default {
  components: {},

  props: [],

  data () {
    return {
      member: {
        ad_soyad: "",
        email: "",
        adres: "",
        ilce: "",
        sehir: "",
        posta_kodu: "",
        ulke: "",
        tel1: "",
        tel2: "",
        tel3: "",
        tc: "",

        fatura_ad: "",
        fatura_adres: "",
        vd: "",
        vno: "",
      },

      invoiceDifferent: false,
      error: null,

      ui: {
        loading: false,
      },
    }
  },

  methods: {
    async submit () {
      if (! this.invoiceDifferent) { //invoice same, reset invoice fields.
        Object.assign(this.member, {
          fatura_ad: "", fatura_adres: "", vd: "", vno: "",
        });
      }

      this.error = false;
      this.ui.loading = true;
      const ret = await this.$post("/save", {member: this.member});
      this.ui.loading = false;
      if (ret.err) return this.error = ret.err;
      this.$store.member = ret.member;
      this.$router.push({name: "support", params: {step: 'destek-tipi'}});
    },
  },

  async created () {
    this.member = (await this.$get("/")).member;
    delete this.member.id;
    if (this.member.tc === "11111111111") this.member.tc = "";
  }
}
</script>

<style scoped>
:deep(.p-inputtext) {
  @apply w-full;
}
</style>
<template>

  <Card class="p-4 py-0 mx-auto max-w-screen-lg" v-if="order">
    <template #title>
      <h1>Rezervasyonunuz alındı</h1>
    </template>
    <template #content>
      <div class="thank-you-section">
        <p>
          Sayın <b>{{ $store.member.ad_soyad }}</b>,
        </p>

        <!-- havale -->
        <p v-if="order.paymentType === 'havale'">
          Açık Radyo Dinleyici Destek Projesine yapmış olduğunuz katkı için teşekkür ederiz.
          Rezervasyonunuz gerçekleştirilmiştir. Yapmayı düşündüğünüz destek
          ile ilgili detaylı bilgi ve ödemeyi yapabileceğiniz banka hesap
          numaraları aşağıda yer almaktadır. Havaleniz elimize ulaştığı anda
          rezervasyonunuz kesinleştirilecektir.
          <b>{{ $store.settings.moneyWireAllowedDays }} gün içinde</b>
          kesinleştirilmeyen rezervasyonlar sistem tarafından otomatik
          olarak iptal edilmektedir.
        </p>

        <!-- cc -->
        <div v-else>
          <p>Ödemeniz alınmış ve rezervasyonunuz gerçekleştirilmiştir.</p>
          <p>Açık Radyo Dinleyici Destek Projesine yapmış olduğunuz katkı için teşekkür ederiz.</p>
        </div>

        <template v-if="order.invoiceAddress">
          <p>
            Fatura bilgileriniz aşağıdaki gibidir:
          </p>

          <div class="invoice-info">
            <p v-for="(p, index) of order.invoiceAddress.split('\n')" :key="index">
              {{ p }}
            </p>
          </div>
        </template>

        <p class="mt-2">
          Rezervasyon kodunuz: <strong>{{ order.id }}</strong>
        </p>

        <!-- havale -->
        <div v-if="order.paymentType === 'havale'">
          <p>
            Lütfen havale / EFT işleminizi gerçekleştirirken
            <b>açıklama alanına yukarıdaki kodu yazınız</b>.
          </p>
          <p>
            Havale yaparken, “Gönderen” bölümüne: destekçi ismini,
            “Alıcı” bölümüne: <b>Anafor Radyo Yayıncılık A.Ş.</b>
            yazmayı lütfen unutmayın.
          </p>
        </div>

      </div>

    </template>
  </Card>

  <Card class="p-4 py-0 mx-auto max-w-screen-lg mt-8">
    <template #title>
      <h2>Desteğiniz</h2>
    </template>
    <template #content>
      <Basket v-if="order" :order="order" :editable="false" />
    </template>
  </Card>

  <Card v-if="order && order.paymentType === 'havale'" class="p-4 py-0 mx-auto max-w-screen-lg mt-8">
    <template #title>
      <h2>Banka hesap numaralarımız</h2>
    </template>
    <template #content>

      <!-- havale -->
      <table class="w-full banks-table">
        <thead class="hidden md:table-header-group">
          <tr>
            <th>Banka</th>
            <th>Hesap no</th>
            <th>IBAN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bank in banks" :key="bank.id">
            <td class="font-bold md:font-normal">{{bank.name}}</td>
            <td>
              <div class="flex justify-between">
                <span class="md:hidden">Hesap no:</span>
                <span class="font-bold md:font-normal">{{bank.accountNo}}</span>
              </div>
            </td>
            <td>
              <div class="flex justify-between">
                <span class="md:hidden">IBAN:</span>
                <span class="font-bold md:font-normal">{{bank.iban}}</span>
              </div>
              <div v-if="bank.swiftCode"
                class="flex justify-between md:justify-start pt-1.5 gap-1">
                <span>Swift kodu: </span>
                <span>{{ bank.swiftCode }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 class="font-bold mt-4">Açık Radyo posta çeki hesabı:</h3>
      <p>Hesap No: <b>559 25 67</b></p>
      <p>Hesap Sahibinin adı: <b>Anafor Radyo Yayıncılık A.Ş.</b></p>
      <p>Hesap Sahibinin adresi: <b>Hacımimi Mah. Lüleci Hendek Cad.
        Koltukçular Çıkmazı No:7 Kat:1-2 Tophane/Beyoğlu</b></p>
    </template>
  </Card>

  <Card class="p-4 py-0 mx-auto max-w-screen-lg mt-8">
    <template #content>
      <p class="mt-4">
        Daha fazla destek olmak için
        <router-link :to="{name: 'support', params: {step: 'destek-tipi'}}">
          lüften tıklayın</router-link>.
      </p>
      <p class="mt-4">
         Oturumu sonlandırmak için
         <a @click="logout()">lüften tıklayın</a>.
      </p>
    </template>
  </Card>
</template>

<script>
import Basket from "./basket/basket.vue";
export default {
  components: { Basket },

  emits: ['logout'],
  props: [],

  data() {
    return {
      banks: [],
      order: null,
    }
  },

  methods: {
    logout () {
      this.$emit('logout');
    }
  },

  async created() {
    if (! this.$store.order.id) {
      return this.$router.replace({name: "support", params: {step: "destek-tipi"}});
    }

    this.banks = (await this.$get("/banks")).banks;
    this.order = JSON.parse(JSON.stringify(this.$store.order));
    this.$store.order.reset(); //empty basket
  }
}
</script>

<style scoped>
.thank-you-section p {
  @apply mb-4;
}

.thank-you-section .invoice-info p {
  @apply mb-auto;
}

.banks-table td, .banks-table th {
  @apply text-left block md:table-cell py-0.5 px-2;
}

.banks-table tr {
  @apply block md:table-row border-b py-4
}
</style>